import React, { useCallback, useState } from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api'

const containerStyle = {
  width: '100%',
  height: '400px',
  marginBottom: '20px',
  borderRadius: '8px'
}

function DeliveryGroupMap({ restaurant, orders }) {
  // Initialize map center with restaurant location
  const center = {
    lat: parseFloat(restaurant.location.coordinates[1]),
    lng: parseFloat(restaurant.location.coordinates[0])
  }

  // Custom marker style for order stops
  const getOrderMarkerIcon = (number) => ({
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor: '#2196F3',
    fillOpacity: 1,
    strokeWeight: 2,
    strokeColor: '#FFFFFF',
    scale: 15,
    labelOrigin: new window.google.maps.Point(0, 0)
  })

  return (
    // <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
      >
        {/* Restaurant marker */}
        <Marker
          position={center}
          icon={{
            url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
            labelOrigin: new window.google.maps.Point(0, -32),
          }}
          label={{
            text: 'Restaurant',
            color: '#000000',
            fontSize: '14px',
            fontWeight: 'bold'
          }}
        />

        {/* Order markers with sequence numbers */}
        {orders && orders.map((stop, index) => (
          <Marker
            key={stop._id}
            position={{
                lat: parseFloat(stop.deliveryAddress.location.coordinates[1]),
                lng: parseFloat(stop.deliveryAddress.location.coordinates[0])
            }}
            icon={getOrderMarkerIcon(index + 1)}
            label={{
              text: (index + 1).toString(),
              color: '#FFFFFF',
              fontSize: '12px',
              fontWeight: 'bold'
            }}
            title={`Delivery #${index + 1}: ${stop.deliveryAddress.deliveryAddress}`}
          />
        ))}
      </GoogleMap>
    // </LoadScript>
  )
}

export default DeliveryGroupMap 