import React, { useState } from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import DataTable from 'react-data-table-component'
import { Box, Modal, Typography, Grid, Card, CardContent, Container, Chip, Paper, Select, MenuItem, FormControl, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, InputLabel } from '@mui/material'
import { format } from 'date-fns'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import useGlobalStyles from '../utils/globalStyles'
import { customStyles } from '../utils/tableCustomStyles'
import SearchBar from '../components/TableHeader/SearchBar'
import TableHeader from '../components/TableHeader'
import { getDeliveryGroups, updateDeliveryGroupStatus, placeDeliveryGroupOrder } from '../apollo'
import {  LocalShipping, Person,   Phone, LocationOn, 
  Info, ShoppingCart, Schedule, Store, DirectionsCar, Route, 
  LocationCity 
} from '@mui/icons-material'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import DeliveryGroupMap from '../components/Maps/DeliveryGroupMap'

const GET_DELIVERY_GROUPS = gql`
  ${getDeliveryGroups}
`

const UPDATE_DELIVERY_GROUP_STATUS = gql`
  ${updateDeliveryGroupStatus}
`

const PLACE_DELIVERY_ORDER = gql`
  ${placeDeliveryGroupOrder}
`

// Add these style objects at the top of the component
const styles = {
  modal: {
    width: '75%',
    marginLeft: '13%',
    overflowY: 'auto'
  },
  paper: {
    bgcolor: '#f8f9fa',
    p: 4,
    borderRadius: 2,
    maxHeight: '90vh',
    overflow: 'auto',
    boxShadow: 3,
    '& .MuiTypography-root': {
      color: '#2d3436'
    },
    '& .MuiListItemText-primary': {
      color: '#2d3436'
    },
    '& .MuiListItemText-secondary': {
      color: '#636e72'
    }
  },
  sectionTitle: {
    color: '#2d3436',
    borderBottom: '2px solid #00b894',
    pb: 1,
    mb: 3
  },
  card: {
    mb: 2, 
    bgcolor: 'white',
    boxShadow: 2,
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
      mr: 1,
      color: '#00b894'
    },
    '&:hover': {
      boxShadow: 3,
      transform: 'translateY(-2px)',
      transition: 'all 0.3s ease'
    }
  },
  orderCard: {
    mb: 2, 
    bgcolor: 'white',
    boxShadow: 2,
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
      mr: 1,
      color: '#00b894'
    },
    '&:hover': {
      boxShadow: 3,
      transform: 'translateY(-2px)',
      transition: 'all 0.3s ease'
    }
  },
  orderHeader: {
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    mb: 2,
    pb: 2,
    borderBottom: '1px solid #e0e0e0'
  },
  select: {
    '& .MuiSelect-select': {
      py: 0.5,
      pl: 1,
      color: '#000000'
    }
  },
  menuItem: {
    color: '#000000'
  },
  formControl: {
    minWidth: 150,
    ml: 2
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 3
  },
  selectContainer: {
    minWidth: 120
  }
}

function DeliveryGroups({ t }) {
  const globalClasses = useGlobalStyles()
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [isPlacingOrder, setIsPlacingOrder] = useState(false)

  const { loading, error, data, refetch } = useQuery(GET_DELIVERY_GROUPS)
  const [updateStatus] = useMutation(UPDATE_DELIVERY_GROUP_STATUS, {
    onCompleted: () => {
      refetch()
      NotificationManager.success(
        t('Status updated successfully'),
        t('Success')
      )
    },
    onError: error => {
      console.error('Error updating status:', error)
      NotificationManager.error(
        t('Error updating status'),
        t('Error')
      )
    }
  })

  const [placeDeliveryOrder] = useMutation(PLACE_DELIVERY_ORDER, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      console.error('Error placing delivery order:', error)
    }
  })

  const handleRowClick = row => {
    setSelectedGroup(row)
    setModalOpen(true)
  }

  const handleStatusChange = async(groupId, newStatus) => {
    try {
      await updateStatus({
        variables: {
          id: groupId,
          status: newStatus
        }
      })
      setSelectedGroup(prev => ({
        ...prev,
        status: newStatus
      }))
    } catch (error) {
      console.error('Failed to update status:', error)
    }
  }

  const handlePlaceDelivery = async(groupId) => {
    try {
      setIsPlacingOrder(true)
      const { data } = await placeDeliveryOrder({
        variables: {
          id: groupId
        }
      })
      if(!data.placeDeliveryGroupOrder._id) {
        NotificationManager.error(
          t('Error placing delivery order'),
          t('Error')
        )
      } else {
        setConfirmDialogOpen(false)
        setModalOpen(false)
        NotificationManager.success(
          t('Delivery order placed successfully'),
          t('Success')
        )
      }
    } catch (error) {
      console.error('Failed to place delivery order:', error)
      NotificationManager.error(
        t('Error placing delivery order'),
        t('Error')
      )
    } finally {
      setIsPlacingOrder(false)
    }
  }

  const columns = [
    {
      name: t('Pick up'),
      selector: row => row.restaurant.name,
      sortable: true
    },
    {
      name: t('Pickup Time'),
      cell: row => row.scheduledPickupTime ? 
        format(new Date(row.scheduledPickupTime), 'd-MMM (eee) h:mm aaa') : '-',
      sortable: true
    },{
      name: t('Addresses'),
      selector: row => row.totalAddresses,
      sortable: true
    },
    {
      name: t('Total Items'),
      cell: row => {
        const totalItems = row.orders.reduce((sum, order) => {
          return sum + order.items.reduce((itemSum, item) => itemSum + item.quantity, 0)
        }, 0)
        return totalItems
      },
      sortable: true
    },
    {
      name: t('Vehicle'),
      selector: row => row.vehicleType,
      sortable: true
    },
    {
      name: t('Status'),
      cell: row => {
        //   if (row.status === 'COMPLETED') return <Chip label="COMPLETED" color="#4CAF50" size="small" />
        //   if (row.status === 'CANCELLED') return <Chip label="CANCELLED" color="#F44336" size="small" />
        //   if (row.status === 'IN_PROGRESS') return <Chip label="IN_PROGRESS" color="#2196F3" size="small" />
        //   if (row.status === 'ASSIGNED') return <Chip label="ASSIGNED" color="#9C27B0" size="small" />
        //   if (row.status === 'FINDING_RIDER') return <Chip label="FINDING_RIDER" color="#00BCD4" size="small" />
        // return <Chip label="PENDING" color="warning" size="small" />
        return <Chip 
            label={row.status} 
            sx={{ 
              ml: 2,
              backgroundColor: 
              row.status === 'COMPLETED' ? '#4CAF50' :
              row.status === 'CANCELLED' ? '#F44336' :
              row.status === 'IN_PROGRESS' ? '#2196F3' :
              row.status === 'ASSIGNED' ? '#9C27B0' :
              row.status === 'FINDING_RIDER' ? '#00BCD4' :
                '#FF9800',  // PENDING
              color: '#fff'  // white text for all statuses
            }}
            size="small"
        />
      },
      sortable: true
    },
  ]

  const regex = searchQuery.length > 2 ? new RegExp(searchQuery.toLowerCase(), 'g') : null

  const filtered = searchQuery.length < 3
    ? data?.getDeliveryGroups
    : data?.getDeliveryGroups.filter(group => {
        return (
          group.restaurant.name.toLowerCase().search(regex) > -1 ||
          group.orderIds.some(id => id.toLowerCase().search(regex) > -1) ||
          group.vehicleType.toLowerCase().search(regex) > -1
        )
      })

  if (error) return `Error! ${error.message}`

  return (
    <>
      <NotificationContainer />
      <Header />
      <Container className={globalClasses.flex} fluid>
        <DataTable
          title={<TableHeader title={t('Delivery Groups')} />}
          columns={columns}
          data={filtered || []}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          pagination
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          onChangeRowsPerPage={setRowsPerPage}
          onChangePage={setPage}
          subHeader
          subHeaderComponent={
            <SearchBar value={searchQuery} onChange={e => setSearchQuery(e.target.value)} onClick={refetch} />
          }
          onRowClicked={handleRowClick}
          pointerOnHover
          highlightOnHover
          customStyles={customStyles}
        />

        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="delivery-group-modal"
          style={styles.modal}
        >
          <Paper sx={styles.paper}>
            {selectedGroup && (
              <>
                <Box sx={styles.headerContainer}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant="h5" component="h2" gutterBottom sx={{ ...styles.sectionTitle, mb: 0 }}>
                      {t('Pickup')}
                    </Typography>
                    {selectedGroup && (
                      <Chip 
                        label={selectedGroup.status} 
                        sx={{ 
                          ml: 2,
                          backgroundColor: 
                            selectedGroup.status === 'COMPLETED' ? '#4CAF50' :
                            selectedGroup.status === 'CANCELLED' ? '#F44336' :
                            selectedGroup.status === 'IN_PROGRESS' ? '#2196F3' :
                            selectedGroup.status === 'ASSIGNED' ? '#9C27B0' :
                            selectedGroup.status === 'FINDING_RIDER' ? '#00BCD4' :
                            '#FF9800',  // PENDING
                          color: '#fff'  // white text for all statuses
                        }}
                        size="small"
                      />
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    {selectedGroup && !['COMPLETED', 'CANCELLED'].includes(selectedGroup.status) && (
                      <FormControl size="small" sx={styles.formControl}>
                        <Select
                          value={selectedGroup.status}
                          onChange={(e) => handleStatusChange(selectedGroup._id, e.target.value)}
                          sx={{
                            ...styles.select,
                            '& .MuiSelect-select': {
                              py: 0.5,
                              pl: 1,
                              color: '#000000'
                            }
                          }}
                          displayEmpty
                        >
                          <MenuItem value="PENDING" sx={styles.menuItem}>PENDING</MenuItem>
                          <MenuItem value="FINDING_RIDER" sx={styles.menuItem}>FINDING_RIDER</MenuItem>
                          <MenuItem value="ASSIGNED" sx={styles.menuItem}>ASSIGNED</MenuItem>
                          <MenuItem value="IN_PROGRESS" sx={styles.menuItem}>IN_PROGRESS</MenuItem>
                          <MenuItem value="COMPLETED" sx={styles.menuItem}>COMPLETED</MenuItem>
                          <MenuItem value="CANCELLED" sx={styles.menuItem}>CANCELLED</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    
                    {selectedGroup && ['PENDING', 'COMPLETED', 'CANCELLED'].includes(selectedGroup.status) && (
                      <Button 
                        variant="contained" 
                        color="primary"
                        onClick={() => setConfirmDialogOpen(true)}
                      >
                        {t('Call Delivery')}
                      </Button>
                    )}
                  </Box>
                </Box>
                
                <Card sx={styles.card}>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Store /> {selectedGroup.restaurant.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} container spacing={2}>
                        {selectedGroup.distanceKmTotal && (
                        <Grid item xs={12} sm={4}>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Route /> {selectedGroup.distanceKmTotal.toFixed(0)} km
                          </Typography>
                        </Grid>
                        )}
                        <Grid item xs={12} sm={4}>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                            <ShoppingCart /> {selectedGroup.orders.reduce((sum, order) => 
                              sum + order.items.reduce((itemSum, item) => itemSum + item.quantity, 0), 0)} items
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                            <DirectionsCar /> {selectedGroup.vehicleType}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                            <LocationOn /> {selectedGroup.restaurant.address}
                          </Typography>
                          {selectedGroup.restaurant.addressDetails && (
                            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', ml: 3 }}>
                              <LocationCity /> {selectedGroup.restaurant.addressDetails}
                            </Typography>
                          )}
                          {selectedGroup.restaurant.addressInstruction && (
                            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', ml: 3 }}>
                              <Info /> {selectedGroup.restaurant.addressInstruction}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>

                {selectedGroup && selectedGroup.restaurant.location && (
                  <>
                    <Typography variant="h6" gutterBottom sx={styles.sectionTitle}>
                      {t('Delivery Route')}
                    </Typography>
                    <DeliveryGroupMap 
                      restaurant={selectedGroup.restaurant}
                      orders={selectedGroup.orders}
                    />
                  </>
                )}

                <Typography variant="h6" gutterBottom sx={styles.sectionTitle}>
                  {t('Orders')}
                </Typography>
                
                {selectedGroup.orders.map(order => (
                  <Card key={order._id} sx={styles.orderCard}>
                    <CardContent>
                      <Box sx={styles.orderHeader}>
                        <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                          <LocalShipping /> {order.orderId}
                        </Typography>
                        <Chip 
                          label={order.orderStatus} 
                          sx={{ 
                            ml: 2,
                            backgroundColor: 
                              selectedGroup.status === 'COMPLETED' ? '#4CAF50' :
                              selectedGroup.status === 'CANCELLED' ? '#F44336' :
                              selectedGroup.status === 'IN_PROGRESS' ? '#2196F3' :
                              selectedGroup.status === 'ASSIGNED' ? '#9C27B0' :
                              selectedGroup.status === 'FINDING_RIDER' ? '#00BCD4' :
                              '#FF9800',  // PENDING
                            color: '#fff'  // white text for all statuses
                          }}
                          size="small" 
                        />
                      </Box>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Person /> {order.user.name}
                          </Typography>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Phone /> {order.user.phone}
                          </Typography>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                            <LocationOn /> {order.deliveryAddress.deliveryAddress}
                          </Typography>
                          {order.deliveryAddress.details && (
                            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', ml: 3 }}>
                              <LocationCity /> {order.deliveryAddress.details}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Info /> {order.deliveryAddress.deliveryInstructions || 'No instructions'}
                          </Typography>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                            <ShoppingCart /> {order.items.reduce((sum, item) => sum + item.quantity, 0)} items
                          </Typography>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Schedule /> {order.orderDeliverySlot?.displayText || 'No slot'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
              </>
            )}
          </Paper>
        </Modal>

        {/* Confirmation Dialog */}
        <Dialog
          open={confirmDialogOpen}
          onClose={() => !isPlacingOrder && setConfirmDialogOpen(false)}
        >
          <DialogTitle>Confirm Delivery Order</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to place this delivery order?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setConfirmDialogOpen(false)} 
              color="primary"
              disabled={isPlacingOrder}
            >
              Cancel
            </Button>
            <Button 
              onClick={() => handlePlaceDelivery(selectedGroup._id)} 
              color="primary" 
              variant="contained"
              disabled={isPlacingOrder}
            >
              {isPlacingOrder ? 'Processing...' : 'Confirm'}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  )
}

export default withTranslation()(DeliveryGroups) 